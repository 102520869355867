import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import Image from "../ImageQuerys/LocalImages";

const PartnerLogos = (props) => {
  const { logos } = props;
  return (
    <Row
      align="middle"
      justify="center"
      gutter={20}
      style={{ marginTop: 70, marginBottom: 70, margin: "auto" }}
    >
      {logos.map((logo, idx) => (
        <Col xs={8} md={4} key={idx.toString()}>
          <Image
            imageName={logo}
            style={{
              // Milena to provide same size logos.
              // Once the assets are sized properly we should remove the idx module check.
              // This is a quick fix for the denver logos
              maxWidth: idx % 2 === 0 ? 200 : 100,
              maxHeight: idx % 2 === 0 ? 200 : 100,
              zIndex: 2,
              margin: "auto",
            }}
            className="local-logos"
          />
        </Col>
      ))}
    </Row>
  );
};

PartnerLogos.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.string),
};
PartnerLogos.defaultProps = {
  logos: [],
};

export default PartnerLogos;
